
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpNotepadEntity from '../entity';

export default class ErpLabelList extends DefaultController {
    tableElem: any;
    datatable: any;

    async init() {
        this.entity = "erp/notepad"
        await super.init();
        await this.loadAll();
    }

    async loadAll() {
        const r = await Utils.entity.getAll(this.entity);
        if (r.status === 200) {
            const entities = r.data;
            const entity = entities[0];
            const form = document.querySelector("#editErpNotepad") as HTMLFormElement;
            await Utils.updateElements(entity, '', form)
        }
    }

    getEntityData(elem: any) {
        return ErpNotepadEntity.getEntityData(elem)
    }

    bindListeners() {
        (document.querySelector("#editErpNotepad") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault()
            const elem = document.querySelector("#editErpNotepad") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                await Utils.showLoader();
                await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                this.toastr.success(`${Utils.translate('erp.notepad.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)

                await this.loadAll()
                await Utils.hideLoader();
            }
        })
    }
}